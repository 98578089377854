<template>
  <div ref="item" class="p-2" :style="resolvePickStatus(item.pickStatus)" style="border-radius: 6px;">
    <div class="container">
      <div class="row">
        <div class="col-shrink p-half">
          <img :src="item.smallImage || require('@/assets/images/undraw/no_images.svg')" style="width: 80px !important; margin-right: 0.625rem" class="rounded text-white">
        </div>
        <div class="col">
          <div class="row">
            <h4 class="text-warning text-wrap">
              {{ item.title }}
            </h4>
          </div>
          <div class="row justify-content-between">
            <div class="text-center mr-1">
              <div class="text-muted">
                Location
              </div>
              <h4 :style="resolveHeading(item.pickStatus)">
                {{ item.locationName || '-' }}
              </h4>
            </div>
            <div class="text-center mr-1">
              <div class="text-muted">
                UPC
              </div>
              <h4 :style="resolveHeading(item.pickStatus)">
                {{ item.upc || '-' }}
              </h4>
            </div>
            <div class="text-center mr-1">
              <div class="text-muted">
                COUNT
              </div>
              <h4 :style="resolveHeading(item.pickStatus)">
                {{ item.itemCount }}
              </h4>
            </div>
          </div>
          <div class="text-center">
            <div class="row justify-content-between">
              <div class="ml-half">
                <small class="text-muted mr-half">ACC#:</small>
                <small>
                  {{ item.accountNo || '-' }}
                </small>
              </div>
              <div class="ml-half">
                <small class="text-muted mr-half">ASIN:</small>
                <small>
                  {{ item.asin || '-' }}
                </small>
              </div>
              <div class="ml-half">
                <small class="text-muted mr-half">MSKU:</small>
                <small>
                  {{ item.msku || '-' }}
                </small>
              </div>
              <div class="ml-half">
                <small class="text-muted mr-half">FNSKU:</small>
                <small>
                  {{ item.fnsku || '-' }}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-shrink p-half">
          <div class="row align-items-center px-1">
            <div class="d-flex flex-column">
              <div class="d-flex mb-half">
                <dx-util-button icon="icon bi-chevron-left" @click="decreaseCount" />
                <input id="quantity" v-model="pickItemForm.pickCount" class="form-control mx-half" type="text" inputmode="numeric" pattern="\d*" name="quantity" style="width:70px">
                <dx-util-button icon="icon bi-chevron-right" @click="increaseCount" />
              </div>
              <div class="d-flex align-items-start">
                <dx-util-button icon="icon icon-psh-receive" class="mr-half flex-grow-1" text="Pick" type="success" @click="updatePickItem" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-shrink pl-1 text-right">
          <dx-util-button icon="remove" class="m-half" type="danger" @click="deleteItem(item.id)" />
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="align-self-center">

      </div>

      <div class="d-flex" style="line-height: 14px;">

      </div>
    </div>
  </div>
</template>

<script>
import fbmService from '@/http/requests/fbm/fbmService'
import { confirm } from 'devextreme/ui/dialog'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      pickItemForm: {
        id: null,
        pickCount: 0,
      },
    }
  },
  watch: {
    item: {
      immediate: true,
      deep: true,
      handler() {
        this.pickItemForm.pickCount = this.item.itemCount
      },
    },
  },
  methods: {
    increaseCount() {
      this.pickItemForm.pickCount += 1
    },
    decreaseCount() {
      if (this.pickItemForm.pickCount > 0) {
        this.pickItemForm.pickCount -= 1
      }
    },
    async updatePickItem() {
      this.pickItemForm.id = this.item.id
      this.pickItemForm.pickCount = parseInt(this.pickItemForm.pickCount, 10)
      await fbmService.updatePickBatchItem(this.pickItemForm)
      this.$emit('emit-item-picked', this.item.pickBatchId)
    },
    resolvePickStatus(status) {
      if (status === 'Completed') return 'background-color: #c8e6c9; color: #000 !important'
      if (status === 'PartiallyCompleted') return 'background-color: #fccfcf; color: #000 !important'
      return ''
    },
    resolveHeading(status) {
      if (status === 'Completed' || status === 'PartiallyCompleted') return 'color: #000 !important'
      return ''
    },
    async deleteItem(itemId) {
      const confirmResult = confirm(
        "Are you sure you want to delete this item? You won't be able to revert this!",
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          await fbmService.deletePickBatchItem(itemId)
          this.$emit('emit-item-picked', this.item.pickBatchId)
        }
      })
    },
  },

}
</script>

<style lang="scss" scoped>
input {
  font-size: 24px;
}
</style>
