<template>
  <div id="pickList">
    <DxDrawer
      :opened.sync="isDrawerOpen"
      :min-size="0"
      height="calc(100vh - 140px)"
      template="list"
      opened-state-mode="shrink"
    >
      <template #list>
        <div style="width: 340px; height: calc(100vh - 140px);" class="card rounded-0">
          <div class="d-flex align-items-center mt-half">
            <div class="card-header border-bottom py-0 mx-0 flex-grow-1">
              <div class="card-title text-warning py-1">
                Pick Lists
              </div>
            </div>
          </div>
          <dx-util-scroll-view>
            <div class="px-half">
              <dx-util-list :data-source="listDataSource" height="calc(100vh - 140px)"
                item-template="batchTemplate" :allow-item-deleting="true"
                :item-delete-mode="'slideItem'" @item-click="openPickList"
              >
                <template #batchTemplate="{data: batch}">
                  <div :class="batch.itemCount === batch.pickCount ? 'bg-light-green': ''" class="p-0 m-0">
                    <div class="text-center" style="padding-bottom: 16px;" :class="batch.batchName === selectedBatchName ? 'blue-border' : ''">
                      <div class="d-flex px-1 pt-half justify-content-between align-items-center">
                        <div classs="m-0 p-0 text-center text-wrap" style="font-size: 14px; font-weight: 800;">
                          {{ batch.batchName }}
                        </div>
                        <dx-util-button icon="remove" styling-mode="text" class="m-0" @click="deleteBatch(batch.id)" />
                      </div>
                      <div class="d-flex justify-content-center">
                        <div class="text-center mr-1">
                          <div class="">
                            Count
                          </div>
                          <div class="badge badge-danger px-1" style="width:72px; opacity: 0.8;">
                            <h4 class="m-0">
                              {{ batch.itemCount }}
                            </h4>
                          </div>
                        </div>
                        <div class="text-center">
                          <div class="">
                            Picked
                          </div>
                          <div class="badge badge-success" style="width:72px; opacity: 0.8;">
                            <h4 class="m-0">
                              {{ batch.pickCount }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </dx-util-list>
            </div>
          </dx-util-scroll-view>
        </div>
      </template>
      <div id="view" class="card rounded-0 my-0 mx-half p-half" style="height: calc(100vh - 140px);">
        <div class="d-flex align-items-center mb-1">
          <dx-util-button class="mr-1" type="success" :icon="isDrawerOpen ? 'icon bi-chevron-left' : 'icon bi-chevron-right'" @click="isDrawerOpen = !isDrawerOpen" />
          <dx-util-button text="Return FBM Force" type="default" @click="$router.go(-1)" />
          <h4 class="flex-grow-1 m-0 text-warning">
            {{ selectedBatchName }}
          </h4>
          <dx-util-button v-if="selectedBatchName" icon="icon bi-download" type="default" @click="generatePdf" />
        </div>
          <dx-util-scroll-view :scroll-by-content="true" :scroll-by-thumb="true">
            <div>
              <dx-util-list :data-source="currentBatch" height="calc(100vh - 140px)"
                item-template="itemTemplate" :allow-item-deleting="true"
                :item-delete-mode="'slideItem'" :active-state-enabled="false"
                :selection-mode="'none'" @item-deleted="onItemDeleted"
              >
              <template #itemTemplate="{data: item}">
                <fbm-pick-item :item="item" @emit-item-picked="reloadBatch" />
              </template>
              </dx-util-list>
            </div>
          </dx-util-scroll-view>
      </div>
    </DxDrawer>
    <div>
      <vue-html2pdf
          ref="html2Pdf"
          :show-layout="false"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="false"
          :filename="`Pick List - ${selectedBatchName}`"
          :pdf-quality="2"
          :manual-pagination="true"
          :paginate-elements-by-height="1100"
          pdf-format="letter"
          pdf-orientation="portrait"
          :pdf-content-width="'810px'"
          @hasDownloaded="hasGenerated"
      >
        <section id="amazonFreightContent" slot="pdf-content" class="px-2 py-3" :class="isPdfGenerating ? '' : 'hidden'">
          <div id="pickListPdf" class="text-black">
            <div class="text-center text-black">
              <h6 class="text-black mb-0 pb-0" style="font-size: 18px; font-weight: 500;">
                Pick List
              </h6>
              <span style="font-size: 12px;">
                {{ selectedBatchName }}
              </span>
            </div>
            <div v-for="(item) in currentBatch" :key="item.id" class="pdf-item" style="margin: 10px">
              <div class="px-2">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="text-black m-0 text-wrap">
                    {{ item.title }}
                  </h6>
                  <span class="d-flex align-items-center" style="min-width: 120px;">
                    <p-icon name="bi-geo-fill" size="12px" class="mr-half" color="#838383" />
                    <span class="text-black m-0" style="font-size: 16px; font-weight: 600;">{{ item.locationName ||'-' }}</span>
                  </span>
                </div>
                <div class="d-flex row-bordered align-items-start">
                  <img :src="item.smallImage || require('@/assets/images/undraw/no_images.svg')" style="width: 42px !important; height: 42px !important; margin-right: 0.625rem" class="rounded text-white">
                  <table>
                    <tr>
                      <th>ACC#</th>
                      <th>UPC</th>
                      <th>ASIN</th>
                      <th>FNSKU</th>
                      <th>MSKU</th>
                      <th>COUNT</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>{{ item.accountNo || '-' }}</td>
                      <td>{{ item.upc || '-' }}</td>
                      <td>{{ item.asin || '-' }}</td>
                      <td>{{ item.fnsku || '-' }}</td>
                      <td>{{ item.msku || '-' }}</td>
                      <td>{{ item.itemCount || '-' }}</td>
                      <td class="text-right">
                        <p-icon name="bi-square" size="14px" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>

    </div>
  </div>
</template>

<script>
import DxDrawer from 'devextreme-vue/drawer'
import { useWindowSize } from '@vueuse/core'
import { computed } from '@vue/composition-api'
import fbmService from '@/http/requests/fbm/fbmService'
import CustomStore from 'devextreme/data/custom_store'
import DataSource from 'devextreme/data/data_source'
import VueHtml2pdf from 'vue-html2pdf'
import { confirm } from 'devextreme/ui/dialog'

// import { v4 as uuidv4 } from 'uuid'
import FbmPickItem from './FbmPickItem.vue'

const listDataSource = new DataSource({
  store: new CustomStore({
    key: 'id',
    loadMode: 'raw',
    load: () => fbmService.getPickBatches(),
    remove: key => {
      fbmService.deletePickBatch(key)
    },
  }),
})

export default {
  components: {
    DxDrawer,
    VueHtml2pdf,
    'fbm-pick-item': FbmPickItem,
  },
  data() {
    return {
      isDrawerOpen: true,
      listDataSource,
      currentBatch: {},
      selectedBatchName: '',
      isPdfGenerating: false,
    }
  },
  setup() {
    const { width } = useWindowSize()
    const smallScreen = computed(() => width.value <= 1000)
    return {
      smallScreen,
    }
  },
  mounted() {
    listDataSource.reload()
  },
  methods: {
    async openPickList(e) {
      this.currentBatch = await fbmService.getPickBatch(e.itemData.id)
      this.selectedBatchName = e.itemData.batchName
      if (this.smallScreen) {
        this.isDrawerOpen = false
      }
    },
    async deleteBatch(pickBatchId) {
      const confirmResult = confirm(
        "Are you sure you want to delete this pick list? You won't be able to revert this!",
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          const result = await await fbmService.deletePickBatch(pickBatchId)
          if (result) {
            listDataSource.reload()
            this.currentBatch = {}
          }
        }
      })
    },
    async onItemDeleted(e) {
      fbmService.deletePickBatchItem(e.itemData.id)
    },
    async reloadBatch(pickBatchId) {
      this.currentBatch = await fbmService.getPickBatch(pickBatchId)
      listDataSource.reload()
    },
    generatePdf() {
      this.isPdfGenerating = true
      this.$refs.html2Pdf.generatePdf()
    },
    hasGenerated() {
      this.isPdfGenerating = false
    },
  },
}
</script>

<style lang="scss">

#view {
  margin-left: 10px;
  margin-top: 10px;
}

.blue-border {
  border-left: 24px solid rgb(85, 224, 88) !important;
  padding: 4x; /* Add padding to make the border more visible */
}
.bg-light-green {
  background-color: rgba(14, 126, 108, 0.3);
}

#pickList {
  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
    background-color: rgba(38, 130, 115, 0.3);
    color: rgb(226, 238, 236);
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused.dx-list-item-selected {
    background-color: rgba(40, 230, 198, 0.3);
  }
}

#pickListPdf {
  table {
    width: 100%;
    height: 100%;
  }
  th {
    font-size: 8px;
    text-align: center;
    font-weight: 300;
    padding: 0;
  }
  td {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 0;
  }
  .row-bordered {
    border-bottom: 1.3px solid #82838e;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }
  .pdf-item {
  page-break-inside: avoid;
  }
}

</style>
